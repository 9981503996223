@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: bold;
  src: local("VAGRoundedBT-Regular"),
    url(./fonts/VAGRoundedBT-Regular.otf) format("opentype");
}

@font-face {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: normal;
  src: local("VAGRoundedStd-Thin"),
    url(./fonts/VAGRoundedStd-Thin.otf) format("opentype");
}

@font-face {
  font-family: "VAGRounded";
  font-style: normal;
  font-weight: normal;
  src: local("VAGRoundedStd-Thin"),
    url(./fonts/VAGRoundedStd-Thin.woff) format("woff");
}

html,
body,
#root {
  font-family: "VAGRounded", "Quicksand", "Helvetica Neue", "Open Sans",
    Helvetica, Arial, sans-serif;
  height: 100%;
  background: #f3f3f3;
}

.raleway {
  font-family: "Raleway", "Quicksand", "Helvetica Neue", "Open Sans", Helvetica,
    Arial, sans-serif;
}

a {
  color: #b33f7b;
  font-weight: bold;
}

a:hover {
  color: rgb(100, 100, 100);
}

.buttonLink {
  cursor: pointer;
}

.buttonLink:hover {
  color: rgb(100, 100, 100);
  text-decoration: underline
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #be8c1a;
}

.dropdown-item:focus,
.dropdown-item:focus-visible {
  outline: none;
}

.billsbyBtn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  line-height: 24px;
  height: 38px;
  border-radius: 19px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  color: #595959;

  background-image: linear-gradient(to right, #f8d17a 0, #f8d17a 100%);
  background-position: 0 1.9em;
  background-repeat: repeat-x;
  background-size: 2px 2px;
  margin-bottom: 4px;
}

.billsbyBtn:hover,
.billsbyBtn:focus,
.billsbyBtn:active {
  color: #be8c1a;
  outline: none;
  text-decoration: none;
}

.billsbyBtn:hover {
  -ms-transform: translate(0px, -0.8px);
  /* IE 9 */
  -webkit-transform: translate(0px, -0.8px);
  /* Safari */
  transform: translate(0px, -0.8px);
}

p {
  font-family: "Raleway", "Quicksand", "Helvetica Neue", "Open Sans", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
}

#main-wrapper {
  min-height: calc(100vh - 54px);
  /*height of navbar*/
  height: 100%;
}

#main-wrapper>div {
  height: 100%;
  /*These two pass down height to pages*/
  min-height: inherit;
}

#footers {
  flex-shrink: 0;
}

/* This was nice to stick to bottom but interferred with animations */
/* #app {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#main-wrapper {
  flex: 1 0 auto;
}
#footers{
  flex-shrink: 0;
} */
.db-title-block {
  display: inline;
  color: white;
  padding-left: 0;
  padding-right: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: 12px 0 0 0, -12px 0 0 0;
  position: relative;
  line-height: 1.4;
}

.db-title-block:before,
.db-title-block:after {
  position: absolute;
  content: " ";
  width: 13px;
  left: -12px;
  top: 0;
  height: 100%;
  border: 1px;
}

.db-title-block:after {
  left: auto;
  right: -12px;
}

.bg-light-mint-trans {
  background: rgba(22, 193, 148, 0.7);
}

.bg-light-mint {
  background-color: #16c194;
}

.bg-light-teal {
  background-color: #12aaaf;
}

.bg-dark-mint {
  background-color: #169f7b;
}

.bg-light-yellow-trans {
  background: rgba(244, 179, 33, 0.7);
}

.bg-light-yellow {
  background-color: #f4b321;
}

.bg-dark-yellow {
  background-color: #be8c1a;
}

.bg-light-pink {
  background-color: #e04f9a;
}

.bg-light-pink-trans {
  background: rgba(224, 79, 154, 0.7);
}

.bg-dark-pink {
  background-color: #b33f7b;
}

.bg-light-grey {
  background-color: rgb(190, 190, 190);
}

.bg-dark-teal {
  background-color: #0c989d;
}

.bg-light-blue {
  background-color: rgb(48, 88, 129);
}

.bg-light-orange {
  background-color: #EB6915;
}



.dark-mint-block {
  box-shadow: 12px 0 0 0 #169f7b, -12px 0 0 0 #169f7b;
  background-color: #169f7b;
}

.dark-yellow-block {
  box-shadow: 12px 0 0 0 #be8c1a, -12px 0 0 0 #be8c1a;
  background-color: #be8c1a;
}

.dark-pink-block {
  box-shadow: 12px 0 0 0 #b33f7b, -12px 0 0 0 #b33f7b;
  background-color: #b33f7b;
}

.dark-grey-block {
  box-shadow: 12px 0 0 0 rgb(100, 100, 100), -12px 0 0 0 rgb(100, 100, 100);
  background-color: rgb(100, 100, 100);
}

.dark-teal-block {
  box-shadow: 12px 0 0 0 #0c989d, -12px 0 0 0 #0c989d;
  background-color: #0c989d;
}

.dark-blue-block {
  box-shadow: 12px 0 0 0 rgb(25, 57, 88), -12px 0 0 0 rgb(25, 57, 88);
  background-color: rgb(25, 57, 88);
}

.dark-orange-block {
  box-shadow: 12px 0 0 0 #d35f12, -12px 0 0 0 #d35f12;
  background-color: #d35f12;
}



.tc-light-yellow {
  color: #f4b321;
}

.tc-dark-yellow {
  color: #be8c1a;
}

.tc-light-pink {
  color: #e04f9a;
}

.tc-light-mint {
  color: #16c194;
}

.tc-dark-mint {
  color: #169f7b;
}

.tc-mid-grey {
  color: #727272;
}

.tc-dark-pink {
  color: #b33f7b;
}

.tc-white {
  color: white;
}

.tc-dark-blue {
  color: #00abed;
}

.tc-plm-blue {
  color: #193958;
}

.tc-dark-teal {
  color: #0c989d;
}

.tc-dark-orange {
  color: rgb(233, 89, 16);
}

/*Transitions*/

@keyframes fadeGrowIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.92, 0.92, 0.92);
    transform: scale3d(0.92, 0.92, 0.92);
  }

  to {
    opacity: 1;
    transition: opacity 150ms, transform 150ms;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeGrowOut {
  from {
    opacity: 1;
    transition: opacity 150ms, transform 150ms;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.92, 0.92, 0.92);
    transform: scale3d(0.92, 0.92, 0.92);
  }
}

.fadeGrow-enter-active {
  animation-name: fadeGrowIn;
  animation-duration: 100ms;
  animation-fill-mode: both;
}

.fadeGrow-appear-active {
  animation-name: fadeGrowIn;
  animation-duration: 100ms;
  animation-fill-mode: both;
}

.fadeGrow-exit-active {
  animation-name: fadeGrowOut;
  animation-duration: 100ms;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-1000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(1000px, 0, 0);
  }
}

.fadeLeft-enter-active {
  animation-name: fadeInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeLeft-appear-active {
  animation-name: fadeInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeLeft-exit-active {
  animation-name: fadeOutRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(1000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-1000px, 0, 0);
  }
}


@keyframes background-zoom {
  0% {
    transform: scale(1.04);
    opacity: 0;
  }

  30% {
    opacity: 100;
  }

  100% {
    transform: scale(1);
  }
}


.fadeRight-enter-active {
  animation-name: fadeInRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeRight-appear-active {
  animation-name: fadeInRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeRight-exit-active {
  animation-name: fadeOutLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fade-enter {
  opacity: 0.01;
}

.fade-appear {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-out;
}

.fade-appear-active {
  opacity: 1;
  transition: opacity 400ms ease-out;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 400ms ease-out;
}



.fade-delay-enter {
  opacity: 0.01;
}

.fade-delay-appear {
  opacity: 0.01;
}

.fade-delay-enter-active {
  opacity: 1;
  transition-delay: 1s;
  transition-duration: 0.4s;
  transition-property: opacity;


}

.fade-delay-appear-active {
  opacity: 1;
  transition-delay: 1s;
  transition-duration: 0.4s;
  transition-property: opacity;

}

.fade-delay-exit-active {
  opacity: 0.01;
  transition-delay: 1s;
  transition-duration: 0.4s;
  transition-property: opacity;

}





.fadeRouter-enter {
  opacity: 0.01;
}

.fadeRouter-appear {
  opacity: 0.01;
}

.fadeRouter-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-out;
  position: absolute;
  width: 100%;
  margin: auto;
}

.fadeRouter-appear-active {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.fadeRouter-exit-active {
  opacity: 0.01;
  transition: opacity 150ms ease-out;
  position: absolute;
  width: 100%;
  margin: auto;
}

.fadeRouter-zeros-enter {
  opacity: 0.01;
}

.fadeRouter-zeros-appear {
  opacity: 0.01;
}

.fadeRouter-zeros-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-out;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
}

.fadeRouter-zeros-appear-active {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.fadeRouter-zeros-exit-active {
  opacity: 0.01;
  transition: opacity 150ms ease-out;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
}





@keyframes slideVerticalIn {
  from {
    transform: translate3d(0, 300px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideVerticalOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 300px, 0);
  }
}

.slide-vertical-enter-active {
  animation-name: slideVerticalIn;
  animation-timing-function: linear;
  animation-duration: 600ms;
  animation-fill-mode: both;
}

.slide-vertical-appear-active {
  animation-name: slideVerticalIn;
  animation-timing-function: linear;
  animation-duration: 600ms;
  animation-fill-mode: both;
}

.slide-vertical-exit-active {
  animation-name: slideVerticalOut;
  animation-timing-function: linear;
  animation-duration: 600ms;
  animation-fill-mode: both;
}








@keyframes excitingIn {
  0% {
    opacity: 0;
    transform: scale3d(0.0, 0.0, 0.0);
  }

  40% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  50% {
    opacity: 1;
  }

  60% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  80% {
    transform: scale3d(1, 1, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes excitingOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.exciting-enter-active {
  animation-name: excitingIn;
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.exciting-appear-active {
  animation-name: excitingIn;
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.exciting-exit-active {
  animation-name: excitingOut;
  animation-duration: 300ms;
  animation-fill-mode: both;
}











.horizontalCollapse-enter {
  max-height: 0px;
  opacity: 0.01;
}

.horizontalCollapse-appear {
  max-height: 0px;
  opacity: 0.01;
}

.horizontalCollapse-enter-active {
  transition: max-height 600ms ease-out, opacity 300ms ease-out;
  max-height: 600px;
  opacity: 1;
  overflow: hidden;
}

.horizontalCollapse-appear-active {
  transition: max-height 600ms ease-out, opacity 300ms ease-out;
  max-height: 600px;
  opacity: 1;
  overflow: hidden;
}

.horizontalCollapse-exit {
  max-height: 600px;
  opacity: 1;
}

.horizontalCollapse-exit-active {
  transition: max-height 600ms ease-out, opacity 300ms ease-out;
  max-height: 0px;
  opacity: 0.01;
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  opacity: 0;
  -webkit-transform: scale3d(0.92, 0.92, 0.92);
  transform: scale3d(0.92, 0.92, 0.92);
  border: none !important;
  background: none !important;
  padding: 0 !important;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 150ms, transform 150ms;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.ReactModal__Content--before-close {
  opacity: 0;
  -webkit-transform: scale3d(0.92, 0.92, 0.92);
  transform: scale3d(0.92, 0.92, 0.92);
}

/*
.REACTMODAL_DRAW {
  position: absolute;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  box-shadow: 0px 0px 4px #343534;
  top: 54px;
  left: auto;
  right: 0;
  bottom: 0;
  width: 300px;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.REACTMODAL_DRAW_AFTEROPEN{
  transition: opacity 150ms, transform 150ms;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.REACTMODAL_DRAW_BEFORECLOSE{
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
*/
@media only screen and (max-width: 568px) {
  .REACTMODAL_DRAW {
    width: 100% !important;
  }
}

.wipeIn {
  animation-duration: 1s;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  animation-name: wipeIn;
}

@keyframes wipeIn {
  from {
    width: 0;
  }
}

.badge-success {
  background-color: #169f7b;
}


.rc-slider>.rc-slider-rail,
.rc-slider>.rc-slider-track,
.rc-slider>.rc-slider-step {
  height: 12px;
}

.rc-slider-handle {
  height: 22px !important;
  width: 22px !important;
  opacity: 1 !important;
  border: 1px solid #e4e4e5 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16) !important;
}

.slider-db-orange>.rc-slider-track {
  background-color: #f4b321 !important;
}

.slider-db-orange>.rc-slider-handle {
  border-color: #f4b321 !important;
}

.slider-db-purple>.rc-slider-track {
  background-color: #e04f9a !important;
}

.slider-db-purple>.rc-slider-handle {
  border-color: #e04f9a !important;
}

.slider-db-green>.rc-slider-track {
  background-color: #16c194 !important;
}

.slider-db-green>.rc-slider-handle {
  border-color: #16c194 !important;
}

.anchor-none:hover {
  text-decoration: none;
}