.avatarHover{
    -webkit-transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out, background-color .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out , background-color .15s ease-in-out;
    color: transparent;
    cursor: pointer;
    border-radius: 50%;
}
.avatarHover:hover{
    background-color: rgba(255,255,255,0.7);
    color: #727272;
}