.space {
    padding-top: 2rem;
}
@media only screen and (min-width: 768px) {
    .space {
        padding-top: 6rem;
    }
}
.left {
    padding-top: 2rem;
    padding-left: 3rem;
}
@media only screen and (min-width: 768px) {
    .left {
        padding-top: 6rem;
        padding-left: 0;
    }
}
@media only screen and (min-width: 992px) {
    .left {
        padding-left: 3rem;
    }
}
@media only screen and (min-width: 1400px) {
    .left {
        padding-left: 0;
    }
}

.greyBubbles {
    background-image: none;
    position: absolute;
    overflow: hidden;
    height: 94px;
    width: 198px;
    overflow: hidden;
    background-size: cover;
    background-position: 0 -70px;
    /* top: -60px; */
    left: -35px;
}
@media screen and (min-width: 768px) {
    .greyBubbles {
        background-image: url('../../img/grey-bubbles.svg');
    }
}
@media screen and (min-width: 992px) {
    .greyBubbles {
        height: 137px;
        width: 250px;
    }
}

.greyBubblesRight {
    position: absolute; 
    background-image: url('../../img/grey-bubbles-right.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 50%;
    /* width: 30%; */
    width: 9em;
    /* background-position: 0px -10%; */
    background-position: 35px -60px;
    top: 0; 
    right: 0; 
    bottom: 0;
    z-index: 1;
    overflow: hidden;
}
@media only screen and (min-width: 576px) {
    .greyBubblesRight {
        /* transform: scale(2); */
        /* transform-origin: right top; */
        /* background-position: 30px -80px; */
        /* height: 89px; */
        width: 25%;
        /* max-width: 800px; */
        /* background-repeat: no-repeat; */
    }
}
@media only screen and (min-width: 768px) {
    .greyBubblesRight {
        width: 20%;
    }
}
@media only screen and (min-width: 1200px) {
    .greyBubblesRight {
        background-position: 80px -90px;
    }
}

.greenBubbles {
    position: absolute; 
    background-image: url('../../img/green-bubbles.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 50%;
    /* width: 30%; */
    width: 9em;
    /* background-position: 0px -10%; */
    background-position: 35px -60px;
    top: 0; 
    right: 0; 
    bottom: 0;
    z-index: 1;
    overflow: hidden;
}
@media only screen and (min-width: 576px) {
    .greenBubbles {
        /* transform: scale(2); */
        /* transform-origin: right top; */
        /* background-position: 30px -80px; */
        /* height: 89px; */
        width: 25%;
        /* max-width: 800px; */
        /* background-repeat: no-repeat; */
    }
}
@media only screen and (min-width: 768px) {
    .greenBubbles {
        width: 20%;
    }
}
@media only screen and (min-width: 1200px) {
    .greenBubbles {
        background-position: 80px -90px;
    }
}

/* @media only screen and (min-width: 768px) {
    .greenBubbles > svg {
        position:absolute;
        top: 30px;
        right: 30px;
    }
} */

.link {
    text-decoration: underline;
    color: #16C194;
}

.list {
    /* list-style: none; */
    /* font-weight: bold; */
    /* line-height: 1.75rem; */
    /* padding: 0 .5rem; */
    font-weight: bold; 
    margin-bottom: 2rem;
}

@media only screen and (min-width: 768px) {
    .list {
        margin-top: 3.7rem;
    }
}
@media only screen and (min-width: 992px) {
    .list {
        margin-top: auto;
        margin-bottom: 2.8rem;
    }
}

.tick {
    color: #16C194;
}

@media only screen and (min-width: 768px) {
    .bottomLeftCurve {
        border-bottom-left-radius: 2rem;
    }
}

.blueBackground {
    background-color: #193958; 
    position: absolute; 
    bottom: 0px; 
    right: 0px;
    left: 0px;
    top: 60%;
    
}
@media only screen and (min-width: 576px) {
    .blueBackground {
        /* top: 45% */
        top: 50%
    }
}
@media only screen and (min-width: 768px) {
    .blueBackground {
        background-color: #193958; 
        position: absolute; 
        top: 0px;
        bottom: 0px;
        left: 60%;
        right: 0px;
    }
}

.blockOne {
    padding-left: 2rem;
}
@media only screen and (min-width: 768px) {
    .blockTwo {
        /* padding-bottom: 6px; */
        padding-left: 0;
    }
}

.blockTwo {
    padding-left: 2rem;
}
@media only screen and (min-width: 768px) {
    .blockTwo {
        /* padding-bottom: 6px; */
        padding-left: 30px
    }
}
@media only screen and (min-width: 992px) {
    .blockTwo {
        /* padding-bottom: 6px; */
        padding-left: 100px
    }
}

.imageLeft {
    /* background-image: url('../../img/smiling-woman-hijab.png'); */
    background-repeat: no-repeat;
    background-size: contain;
}