.sidebar {  
    flex: 0 0 300px; 
    /* use this instead of width - fixed width across all screen sizes*/
    overflow-y: auto;
    width: 300px !important;
}

/* CSS FOR SMALL SIDENAV */
.sidebar-small{
    width: 50px !important;
}

/* TEXT NORMAL STATE */
.sideNavRowItem a{
    text-decoration: none !important;
    color: black;
    transition: color 0.15s;
}

/* TEXT HOVER STATE */
.sideNavRowItem a:hover{
    color:#be8c1a !important;
    border-bottom-color: #be8c1a !important;

}

/* BACKGROUND NORMAL STATE */
.sideNavSubItemRow, .sideNavProductRow{
    transition: background-color 0.15s,border-color 0.15s; ;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: transparent;
}

/* BACKGROUND HOVER STATE */
.sideNavSubItemRow:hover, .sideNavProductRow:hover{
    background-color: white;    
    border-bottom-color: #be8c1a !important;
}


/* CURRENT PAGE STATE */
a.active>.sideNavSubItemRow{
    color:#be8c1a !important; 
}

a.active>.sideNavProductRow{
    /* background-color: #eaeaea; */
    color:#be8c1a !important; 
}




































