.edit{
    position: absolute;
    width: calc(100% + 44px);
    height: calc(100% + 6px);
    top: -3px;
    left: -4px;
    -webkit-transition: box-shadow .15s ease-in-out, color .15s ease-in-out,transform .15s ease-in-out, background-color .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, color .15s ease-in-out ,transform .15s ease-in-out, background-color .15s ease-in-out;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    cursor: pointer;
    text-align: right;
    padding-top: 3px;
    padding-right: 5px;
    color: #727272;
    line-height: 0.9;
}
.edit:hover{
    -ms-transform: translate(0px, -0.8px); /* IE 9 */
    -webkit-transform: translate(0px, -0.8px); /* Safari */
    transform: translate(0px, -0.8px);
    background-color: rgba(244,179,33,0.2);
    border-color: rgba(244,179,33,1);
    box-shadow: 0 0 0 0.2rem rgba(244,179,33,0.5);
    color: #be8c1a;
}
.edit:active{
    background-color: rgba(244,179,33,0.5);
}
.edit>.text{
    color: transparent;
}
.edit:hover>.text{
    color: #727272;
}