.side-drawer {
    height: calc(100% - 54px);
    background: #fff;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 54px;
    left: 0;
    width: 300px;
    z-index: 200;
    transform: translateX(-110%);
    transition: transform 0.3s ease-out;
    overflow-y: auto;
    bottom: 0px;
  }
  
  .side-drawer.open {
    transform: translateX(0);
  }
  
  
  @media (min-width: 769px) {
      .side-drawer {
          display: none;
      }
  }

  /* @media (min-width: 600px) {
    .side-drawer.open {
        width: 100%;
    }
} */
  