.tickItem a:hover{
    text-decoration: none !important
}
.tickItem a{
    color: inherit
}
.tickItemActive:hover{
    -ms-transform: translate(0px, -0.8px); /* IE 9 */
    -webkit-transform: translate(0px, -0.8px); /* Safari */
    transform: translate(0px, -0.8px);
    box-shadow: 0 0 0 0.2rem rgba(244,179,33,0.5);
    cursor: pointer;
}
.tickItem{
    -webkit-transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out,transform .15s ease-in-out, background-color .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out ,transform .15s ease-in-out, background-color .15s ease-in-out;
    padding: 4px 12px;
}