.dropzone{
    -webkit-transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out,transform .15s ease-in-out, background-color .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out ,transform .15s ease-in-out, background-color .15s ease-in-out;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    box-shadow: 0 0 0 0.2rem rgba(244,179,33,0.5);
    cursor: pointer;
    outline: none;
    text-align: center;
}
.dropzoneActive, .dropzone:hover{
    background-color: rgba(244,179,33,0.2);
    border-color: rgba(244,179,33,1);
    color: #be8c1a;
}
.dropzoneContainer{
    height: 120px;
    margin-bottom: 12px;
    padding:12px;

}