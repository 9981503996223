.columnbanner {
  padding-top: 74px;
  padding-bottom: 90px;
  overflow: hidden; }
  .columnbanner__left, .columnbanner__right {
    flex: 1; }
  .columnbanner__left {
    margin-right: 50px; }
    @media (max-width: 1000px) {
      .columnbanner__left {
        margin-right: 25px; } }
  .columnbanner__inner {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    overflow: visible; }
    .columnbanner__inner:after {
      background-image: url(img/circles.svg);
      background-position: right;
      background-repeat: no-repeat;
      background-size: auto 100%;
      content: "";
      display: block;
      top: -71px;
      bottom: -86px;
      left: 0;
      width: 100%;
      position: absolute;
      z-index: -1; }
    @media (max-width: 769px) {
      .columnbanner__inner {
        display: block; }
        .columnbanner__inner .columnbanner__left {
          margin: 0 0 70px 0; } }
  .columnbanner__small {
    max-width: 270px;
    margin: auto;
    display: block; }
  .columnbanner__laptop {
    width: 100%;
    height: auto; }
  .columnbanner p {
    font-size: 1.125em;
    line-height: 1.66667em; }
  .columnbanner figure {
    margin: 0; }
  .columnbanner figcaption {
    margin-top: 23px;
    font-size: 15px;
    text-align: center; }

p.smaller {
  font-size: 0.9375em; }

.container.container-medium {
  max-width: 725px; }

.container-medium {
  max-width: 725px;
  margin-right: auto;
  margin-left: auto; }

p {
  line-height: 1.5625em; }

.card {
  border-radius: 5px; }

.card.card-subtle {
  box-shadow: none;
  border: 1px solid #e4e4e5; }
  @media (min-width: 700px) {
    .card.card-subtle .card-body {
      padding: 2.125rem 1.25rem 2.125rem 2.5rem; } }

.tick-list {
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;  
}
.tick-list li {
  margin-bottom: 0.5em;
  background-image: url("img/tick_icon.svg");
  background-position: left 0.3em;
  background-size: auto 1.125rem;
  background-repeat: no-repeat;
  min-height: 1.125rem;
  padding-left: 40px;
  font-size: 0.9375rem;
  line-height: 2em;
}
.tick-list li:last-of-type {
  margin-bottom: 0; 
}

.leaf-green-tick-list {
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;  
}
.leaf-green-tick-list li {
  margin-bottom: 0.5em;
  background-image: url("img/tick-icon-leaf-green.svg");
  background-position: left 0.3em;
  background-size: auto 1.125rem;
  background-repeat: no-repeat;
  min-height: 1.125rem;
  padding-left: 40px;
  font-size: 0.9375rem;
  line-height: 2em;
}
.leaf-green-tick-list li:last-of-type {
  margin-bottom: 0; 
}


.tick-list .orange-tick {
  background-image: url("img/tick-icon-orange.svg");
}
.tick-list .leaf-green-tick {
  background-image: url("img/tick-icon-leaf-green.svg");
}
      
mark {
  background: none;
  color: #e04f9a;
  font-weight: bold; }

.p-link {
  background: none;
  color: #e04f9a;
  font-weight: bold; }

.calculate-block__cards {
  display: flex;
  margin-bottom: 56px; }
  @media (max-width: 768px) {
    .calculate-block__cards {
      display: block; }
      .calculate-block__cards > div {
        margin-right: 0 !important; } }
  .calculate-block__cards > div {
    flex: 1;
    margin-right: 30px; }
    @media (max-width: 900px) {
      .calculate-block__cards > div {
        margin-right: 15px; } }
    .calculate-block__cards > div:last-of-type {
      margin-right: 0; }
  .calculate-block__cards .card,
  .calculate-block__cards .card-body {
    height: 100%; }
  .calculate-block__cards .card-body {
    align-items: center;
    display: flex;
    flex-direction: column; }
    .calculate-block__cards .card-body .slider {
      margin-top: auto; }

.calculate-block__output {
  margin-top: 45px;
  width: 60px;
  text-align: center;
  border: 1px solid #e4e4e5;
  outline: none !important;
  background: #f3f3f3;
  border-radius: 5px;
  height: 40px;
  padding-top: 6px;
  box-sizing: border-box; }

.calculate-block input::-webkit-outer-spin-button,
.calculate-block input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.calculate-block input[type="number"] {
  -moz-appearance: textfield; }

.calculate-block .btn {
  margin: auto; }

.calculate-block p {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 32px; }

.slider {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  outline: none !important;
  background-color: #f3f3f3;
  background-size: 50% 10px;
  background-repeat: no-repeat;
  background-position: left 50%;
  -webkit-appearance: none;
  width: 100%;
  border-radius: 0 !important;
  box-shadow: none !important; }
  .slider .rangeslider__fill {
    box-shadow: none !important;
    border-radius: 0 !important; }
  .slider .rangeslider__handle {
    outline: none !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #e4e4e5 !important; }
    .slider .rangeslider__handle:after {
      display: none; }
  .slider.orange .rangeslider__fill {
    background: #f4b321 !important; }
  .slider.purple .rangeslider__fill {
    background: #e04f9a !important; }
  .slider.green .rangeslider__fill {
    background: #16c194 !important; }
  .slider:focus {
    outline: none; }

.gridlist__wrap {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none; }

.gridlist__item {
  text-align: left;
  margin-right: 45px;
  margin-bottom: 30px;
  width: calc(33.3333% - 30px); }
  @media (min-width: 900px) {
    .gridlist__item:nth-of-type(3n) {
      margin-right: 0; } }
  @media (max-width: 900px) {
    .gridlist__item {
      width: calc(50% - 23px); }
      .gridlist__item:nth-of-type(2n) {
        margin-right: 0; } }
  @media (max-width: 576px) {
    .gridlist__item {
      width: 100%;
      margin-right: 0 !important; } }
  .gridlist__item img {
    width: 50px;
    height: 50px;
    margin-bottom: 27px; }
  .gridlist__item h4 {
    line-height: 1.25em;
    font-weight: bold;
    margin-bottom: 20px; }
  .gridlist__item .btn {
    margin: auto; }

.video-wrap {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative; }
  .video-wrap iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.pd-header {
  padding-top: 61px;
  padding-bottom: 32px;
  overflow: hidden; }
  .pd-header > div {
    overflow: visible !important; }
  .pd-header .container {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
    .pd-header .container:after {
      pointer-events: none;
      content: "";
      display: block;
      top: -60px;
      bottom: -30px;
      width: 100%;
      right: 0;
      position: absolute;
      background-image: url(img/circles.svg);
      background-position: right;
      background-repeat: no-repeat;
      background-size: auto 100%;
      z-index: -1; }
    @media (max-width: 600px) {
      .pd-header .container {
        align-items: flex-start; } }
  .pd-header__inner {
    max-width: 762px;
    margin-right: 60px; }
    @media (max-width: 600px) {
      .pd-header__inner {
        margin-right: 15px; } }
  .pd-header__logo {
    width: 142px;
    height: auto;
    margin-left: auto; }
    @media (max-width: 600px) {
      .pd-header__logo {
        width: 100px; } }

.tabs-nav {
  display: flex;
  justify-content: center; }
  .tabs-nav__btn {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    padding: 18px;
    min-width: 210px;
    display: block;
    background-color: white;
    text-align: center;
    text-decoration: none !important;
    font-size: 1.375em;
    line-height: 1.20833em;
    border: 0;
    -webkit-appearance: none;
    appearance: none;
    font-weight: bold;
    color: white !important;
    margin: 0 1px;
    border-radius: 12px 12px 0px 0px;
    outline: none !important;
    cursor: pointer; }
    @media (max-width: 900px) {
      .tabs-nav__btn {
        min-width: 1px;
        padding: 12px;
        font-size: 2.3vw; } }
    @media (max-width: 500px) {
      .tabs-nav__btn {
        padding: 10px;
        font-size: 3.4vw; } }
  .tabs-nav__btngreen {
    background-color: #169f7b; }
    .tabs-nav__btngreen.active, .tabs-nav__btngreen:hover {
      background-color: white;
      color: #169f7b !important; }
  .tabs-nav__btnteal {
    background-color: #0c989d; }
    .tabs-nav__btnteal.active, .tabs-nav__btnteal:hover {
      background-color: white;
      color: #0c989d !important; }

@keyframes fade-in {
  from {
    transform: scale(0.9);
    opacity: 0; }
  to {
    opacity: 1;
    transform: none; } }

.green-list {
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0; }
  .green-list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 8px;
    line-height: 1.875em;
    font-family: "Raleway"; }
    .green-list li:last-of-type {
      margin-bottom: 0; }
    .green-list li:before {
      content: "";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: #16c194;
      left: 0px;
      top: 0.73em;
      border-radius: 100%; }

.installation-block {
  display: flex;
  align-items: center;
  margin-bottom: 66px; }
  @media (max-width: 700px) {
    .installation-block {
      align-items: flex-start;
      flex-direction: column-reverse; } }
  .installation-block img {
    max-width: 351px;
    width: 100%;
    height: auto; }
    @media (max-width: 700px) {
      .installation-block img {
        width: 80%;
        margin: 40px auto 0 auto; } }
  .installation-block__inner {
    background-color: #f3f3f3;
    padding: 30px;
    flex: 1;
    margin-left: 30px;
    text-align: left; }
    @media (max-width: 700px) {
      .installation-block__inner {
        margin-left: 0px;
        margin-right: 10px;
        padding: 15px; } }
  .installation-block__list {
    padding: 0;
    margin: 0;
    padding-left: 1em;
    line-height: 1.875em; }
    .installation-block__list li {
      padding-left: 1em;
      margin-bottom: 10px; }
      .installation-block__list li:last-of-type {
        margin-bottom: 0; }

.product-cta .btn {
  width: 83px;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.product-cta .card {
  height: 100%; }

.row.justify-content-around > .col-md-4 > .card {
  height: 100%; }

.accordion {
  border: 1px solid #e4e4e5;
  border-radius: 5px;
  padding: 40px;
  position: relative;
  margin-bottom: 20px; }
  .accordion:last-of-type {
    margin-bottom: 0px; }
  .accordion__heading {
    font-size: 1.5em;
    line-height: 1.25em;
    font-weight: bold;
    padding-right: 40px; }
  .accordion__content {
    animation: fade-in 0.5s cubic-bezier(0.55, 0, 0.1, 1) forwards;
    display: none; }
  .accordion.open .accordion__content {
    display: block; }
  .accordion.open .accordion__button:before {
    display: none; }
  .accordion__content p,
  .accordion__content li {
    line-height: 1.875em; }
  .accordion__button {
    appearance: none;
    -webkit-appearance: none;
    display: block;
    width: 23px;
    height: 23px;
    position: absolute;
    top: 43px;
    right: 30px;
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: none !important; }
    .accordion__button:after {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background-color: #f4b321;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    .accordion__button:before {
      content: "";
      display: block;
      height: 23px;
      width: 4px;
      background-color: #f4b321;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

.fi {
  animation: fade-in 0.5s cubic-bezier(0.55, 0, 0.1, 1) forwards; }

[style*="/static/media/circles_bg"] {
  min-height: 100%; }
