
.card{
    -webkit-transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out,transform .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out ,transform .15s ease-in-out;
    border: none;
    /* box-shadow: 0 1px 4px 0 rgba(0,0,0,.15); */
    box-shadow:0 8px 12px -5px rgba(0,0,0,.4);
    padding: 40px 24px;
    margin-bottom: 12px;
    margin-top: 12px;
}

.circleCard{
    -webkit-transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out,transform .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out ,transform .15s ease-in-out;
    border: none;
    /* box-shadow: 0 1px 4px 0 rgba(0,0,0,.15); */
    /* box-shadow:0 8px 12px -5px rgba(0,0,0,.4); */
    /* padding: 40px 0px; */
    /* margin-bottom: 12px;
    margin-top: 12px; */
}

.cardHover:hover{
    opacity: 0.8;
    -ms-transform: translate(0px, -2px); /* IE 9 */
    -webkit-transform: translate(0px, -2px); /* Safari */
    transform: translate(0px, -2px);

}

/*green*/
.greenHover:hover {
    box-shadow: 0 0 0 0.2rem rgba(22,159,123,0.5);
}

/*yellow*/
.yellowHover:hover {
    box-shadow: 0 0 0 0.2rem rgba(244,179,33,0.5);
}

/*pink*/
.pinkHover:hover {
    box-shadow: 0 0 0 0.2rem rgba(224,79,154,0.5);
}

/*teal*/
.tealHover:hover {
    box-shadow: 0 0 0 0.2rem rgba(12,152,157,0.5);
}

/*green*/
.colorGreen {
    background-color: #16c194;
    color: white!important;
}


/*yellow*/
.colorYellow {
    background-color: #f4b321;
    color: white!important;
}

/*pink*/
.colorPink {
    background-color: #e04f9a;
    color: white!important;
}

/*teal*/
.colorTeal {
    background-color: #0c989d;
    color: white!important;
}



.colorNone {
    background-color: none!important;
}
