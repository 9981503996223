.btn.active{
    color: #be8c1a;
}
.btn .btn-inherit{
    background-color: #595959;
    transition: background-color .15s ease-in-out;
}
.btn.active .btn-inherit, .btn:hover .btn-inherit, .btn:active .btn-inherit, .btn:focus .btn-inherit{
    background-color: #be8c1a!important;
}
