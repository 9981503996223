.p {
    font-size: .9rem;
    letter-spacing: -0.01em;
    line-height: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.row {
    /* border:yellow solid 2px; */
    margin-bottom: -100px;
    /* max-height: 300px; */
}

.col {
    /* border: solid blueviolet 2px; */
}

.container {
    position: relative; 
    padding-top: 100%;
    /* border: aqua solid 2px; */
    background-repeat: no-repeat;
    /* height: 200px; */
    width: 100%;
    margin-bottom: -50px;

}
@media only screen and (min-width: 768px) {
    .container {
        margin-bottom: 0;
    }
}

.imageBox {
    position: absolute;
    background-image: url('https://practiceunbound.blob.core.windows.net/stock-images/dan-jenkinson.png');
    background-repeat: no-repeat;
    overflow: visible;
    padding-top: 100%;
    width: clamp(200px, 80vw, 460px);
    background-size: contain;
    top: -40px;
    left: 3vw;
}
@media only screen and (min-width: 768px) {
    .imageBox {
        height: clamp(200px, 80vw, 300px);
        width: clamp(200px, 80vw, 300px);
        /* width: 1000px; */
        /* height: 350px; */
        left: -10px;
        top: -30px;
    }
}
@media only screen and (min-width: 992px) {
    .imageBox {
        height: 35vw;
        width: 35vw;
        height: clamp(200px,35vw, 300px);
        top: -45px;
        left: 2vw;
    }
}
@media only screen and (min-width: 1200px) {
    .imageBox {
        height: clamp(200px, 80vw,325px);
        width: clamp(200px, 80vw, 325px);
        /* width: 1000px; */
        /* height: 350px; */
        left: 90px;
        top: -40px;
    }
}
@media only screen and (min-width: 1600px) {
    .imageBox {
        height: clamp(200px, 80vw, 350px);
        width: clamp(200px, 80vw, 350px);
        left: 90px;
        top: -45px;
    }
}
@media only screen and (min-width: 3000px) {
    .imageBox {
        height: clamp(200px, 80vw, 300px);
        width: clamp(200px, 80vw, 300px);
        left: 3vw;
        top: -1vw;
    }
}

.absoluteBubbleBox {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.relativeChildBubbleBox {
    position: relative;
    height: 100%;
    width: 100%;
    /* border: purple solid 4px;  */
    max-height: clamp(200px, 84vw, 520px);
}
@media only screen and (min-width: 992px) {
    .relativeChildBubbleBox {
        max-height: 318px;
    }
}

.whiteTop {
    position: absolute;
    background-color:white;
    width: 130%;
    height: 50px;
    top: -50px;
    left: -50px;
    z-index: -2;
}
@media only screen and (min-width: 576px) {
    .whiteTop {
        width: 10000px;
        left: -1000px
    }
}
.whiteBottom {
    position: absolute;
    background-color:white;
    width: 130%;
    height: 200px;
    top: 310px;
    left: -50px;
    display: none;
    z-index: -2;
}
@media only screen and (min-width: 768px) {
    .whiteBottom {
        height: 220px;
        width: 10000px;
        left: -1000px;
        display: block;
        top: 260px;
    }
}

.bubblePink {
    background-image: url('../../img/image-bubble-pink.svg');
    background-repeat: no-repeat;
    background-size:contain;
    position: absolute;
    height: 100%;
    width: 15%;
    inset: 10% 0 0 5%;
    z-index: -1;
}

.bubbleGreen {
    position:absolute;
    background-image: url('../../img/image-bubble-green.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 18%;
    inset: 78% 0 0 72%;
    top: min(83%, 380px);
    z-index: 1;
}
@media only screen and (min-width: 992px) {
    .bubbleGreen {
        top: 95%;
        left: min(72%, 260px);
    }
}
@media only screen and (min-width: 1200px) {
    .bubbleGreen {
        top: 85%;
        left: min(72%, 260px);
    }
}
@media only screen and (min-width: 1600px) {
    .bubbleGreen {
        top: 280px;
        left: min(72%, 260px);
    }
}

.bubbleOrange {
    position:absolute;
    background-image: url('../../img/image-bubble-orange.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 40%;
    inset: 50% 0 0 -25%;
    z-index: -1;
}
/* .bubblesTwo {
    height: clamp(40px, 10vw, 100px);
    width: clamp(40px, 10vw, 100px);
    top: 20px;
    bottom: 0;
    left: -40px;
    right: 0;
} */

.quote {
    /* margin-top: 1em; */
    margin-bottom: 4rem;
    color: hotpink;
}
@media only screen and (min-width: 576px) {
    .quote {
        /* margin-bottom: 0; */
    }
}
@media only screen and (min-width: 768px) {
    .quote {
        padding-top: 35px;
        max-width: 550px;
    }
}