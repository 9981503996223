/*green*/
.colorStandard {
    background-color: #16c194;
    border-color: #16c194;
    color: white!important;
}
.colorStandard:hover,.colorStandard:active {
    background-color: #169f7b;
    color: white!important;
}
.colorStandard:focus {
    box-shadow: 0 0 0 0.2rem rgba(22,159,123,0.5);
    color: white!important;
}
/* orange */
.colorOrange {
    background-color: #EB6915;
    border-color: #EB6915;
    color: white!important;
}
.colorOrange:hover,.colorOrange:active {
    background-color: #f7751f;
    color: white!important;
}
.colorOrange:focus {
    box-shadow: 0 0 0 0.2rem rgba(22,159,123,0.5);
    color: white!important;
}

/*yellow*/
.colorYellow {
    background-color: #f4b321;
    color: white!important;
}
.colorYellow:hover,.colorYellow:active {
    background-color: #be8c1a;
    color: white!important;
}
.colorYellow:focus {
    box-shadow: 0 0 0 0.2rem rgba(244,179,33,0.5);
    color: white!important;
}

.colorYellowOutline {
    background-color: #f4b321;
    border-color: #f4b321;
    color: white!important;
    box-shadow: 0 3px 0 0 #f4b321 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #f4b321 !important; /* Border top */
    box-shadow: -3px 0 0 0 #f4b321 !important; /* Border left */
    box-shadow: 3px 0 0 0 #f4b321 !important; /* Border right */
    box-shadow: 0 0 0 3px #f4b321 !important; /* All the borders by using the spread properties */
}
.colorYellowOutline:hover,
.yellowOutline:focus,
.yellowOutline:active{
    background-color: #be8c1a;
    border-color: #be8c1a;
    color: white!important;
    box-shadow: 0 3px 0 0 #be8c1a !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #be8c1a !important; /* Border top */
    box-shadow: -3px 0 0 0 #be8c1a !important; /* Border left */
    box-shadow: 3px 0 0 0 #be8c1a !important; /* Border right */
    box-shadow: 0 0 0 3px #be8c1a !important; /* All the borders by using the spread properties */
}

.colorYellowHollow {
    background-color: white;
    border-color: white;
    color: #f4b321 !important;
    box-shadow: 0 3px 0 0 #f4b321 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #f4b321 !important; /* Border top */
    box-shadow: -3px 0 0 0 #f4b321 !important; /* Border left */
    box-shadow: 3px 0 0 0 #f4b321 !important; /* Border right */
    box-shadow: 0 0 0 3px #f4b321 !important; /* All the borders bY using the spread properties */
}
.colorYellowHollow:hover,
.yellowHollow:focus,
.yellowHollow:active{
    background-color: #f4b321;
    border-color: #f4b321;
    color: white!important;
    box-shadow: 0 3px 0 0 #f4b321 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #f4b321 !important; /* Border top */
    box-shadow: -3px 0 0 0 #f4b321 !important; /* Border left */
    box-shadow: 3px 0 0 0 #f4b321 !important; /* Border right */
    box-shadow: 0 0 0 3px #f4b321 !important; /* All the borders by using the spread properties */
}

/*pink*/
.colorPink {
    background-color: #e04f9a;
    border-color: #e04f9a;
    color: white!important;
}
.colorPink:hover,.colorPink:active {
    background-color: #b33f7b;
    color: white!important;
}
.colorPink:focus {
    box-shadow: 0 0 0 0.2rem rgba(224,79,154,0.5);
    color: white!important;
}
/*Teal*/
.colorTeal {
    background-color: #0c989d;
    border-color: #0c989d;
    color: white!important;
}
.colorTeal:hover,.colorTeal:active {
    background-color: #0c989d;
    color: white!important;
}
.colorTeal:focus {
    box-shadow: 0 0 0 0.2rem rgba(12,152,157,0.5);
    color: white!important;
}
/* green */
.colorGreen {
    background-color: #60AE4C;
    border-color: #7bc767;
    color: white!important;
}
.colorGreen:hover,.colorGreen:active {
    background-color: #7bc767;
    color: white!important;
}
.colorGreen:focus {
    box-shadow: 0 0 0 0.2rem rgba(12,152,157,0.5);
    color: white!important;
}
/*none*/
.colorNone {
    box-shadow: none!important;
    background-color: rgba(255,255,255,0);
    color: #595959;
    border: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px;
    
}
.colorNone:hover,.colorNone:focus,.colorNone:active{
    color: #be8c1a;
}

/* .colorWhite {
    box-shadow: none!important;
    background-color: rgba(255,255,255,0);
    color: white;
    border: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px;
    
}
.colorWhite:hover,.colorWhite:focus,.colorWhite:active{
    color: #be8c1a;
} */

.colorStandardOutline {
    background-color: #16c194;
    border-color: #16c194;
    color: white!important;
    box-shadow: 0 3px 0 0 #16c194 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #16c194 !important; /* Border top */
    box-shadow: -3px 0 0 0 #16c194 !important; /* Border left */
    box-shadow: 3px 0 0 0 #16c194 !important; /* Border right */
    box-shadow: 0 0 0 3px #16c194 !important; /* All the borders by using the spread properties */
}
.colorStandardOutline:hover,
.StandardOutline:focus,
.StandardOutline:active{
    background-color: #22E7B7;
    border-color: #22E7B7;
    color: white!important;
    box-shadow: 0 3px 0 0 #22E7B7 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #22E7B7 !important; /* Border top */
    box-shadow: -3px 0 0 0 #22E7B7 !important; /* Border left */
    box-shadow: 3px 0 0 0 #22E7B7 !important; /* Border right */
    box-shadow: 0 0 0 3px #22E7B7 !important; /* All the borders by using the spread properties */
}

.colorStandardHollow {
    background-color: white;
    border-color: white;
    color: #16c194 !important;
    box-shadow: 0 3px 0 0 #16c194 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #16c194 !important; /* Border top */
    box-shadow: -3px 0 0 0 #16c194 !important; /* Border left */
    box-shadow: 3px 0 0 0 #16c194 !important; /* Border right */
    box-shadow: 0 0 0 3px #16c194 !important; /* All the borders by using the spread properties */
}
.colorStandardHollow:hover,
.StandardHollow:focus,
.StandardHollow:active{
    background-color: #16c194;
    border-color: #16c194;
    color: white!important;
    box-shadow: 0 3px 0 0 #16c194 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #16c194 !important; /* Border top */
    box-shadow: -3px 0 0 0 #16c194 !important; /* Border left */
    box-shadow: 3px 0 0 0 #16c194 !important; /* Border right */
    box-shadow: 0 0 0 3px #16c194 !important; /* All the borders by using the spread properties */
}

.colorOrangeOutline {
    background-color: #EB6915;
    border-color: #EB6915;
    color: white!important;
    box-shadow: 0 3px 0 0 #EB6915 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #EB6915 !important; /* Border top */
    box-shadow: -3px 0 0 0 #EB6915 !important; /* Border left */
    box-shadow: 3px 0 0 0 #EB6915 !important; /* Border right */
    box-shadow: 0 0 0 3px #EB6915 !important; /* All the borders by using the spread properties */
}
.colorOrangeOutline:hover,
.orangeOutline:focus,
.orangeOutline:active{
    background-color: #f7751f;
    border-color: #f7751f;
    color: white!important;
    box-shadow: 0 3px 0 0 #f7751f !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #f7751f !important; /* Border top */
    box-shadow: -3px 0 0 0 #f7751f !important; /* Border left */
    box-shadow: 3px 0 0 0 #f7751f !important; /* Border right */
    box-shadow: 0 0 0 3px #f7751f !important; /* All the borders by using the spread properties */
}

.colorNoneOutline {
    box-shadow: none!important;
    background-color: rgba(255,255,255,0);
    color: white;
    box-shadow: 0 3px 0 0 white !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 white !important; /* Border top */
    box-shadow: -3px 0 0 0 white !important; /* Border left */
    box-shadow: 3px 0 0 0 white !important; /* Border right */
    box-shadow: 0 0 0 3px white !important; /* All the borders by using the spread properties */
}
.colorNoneOutline:hover,
.StandardOutline:focus,
.StandardOutline:active{
    background-color: #16c194;
    border-color: #16c194;
    color: white!important;
    box-shadow: 0 3px 0 0 #16c194 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #16c194 !important; /* Border top */
    box-shadow: -3px 0 0 0 #16c194 !important; /* Border left */
    box-shadow: 3px 0 0 0 #16c194 !important; /* Border right */
    box-shadow: 0 0 0 3px #16c194 !important; /* All the borders by using the spread properties */

    /* background-color: #e04f9a;
    border-color: #e04f9a;
    color: white!important;
    box-shadow: 0 3px 0 0 #e04f9a !important; 
    box-shadow: 0 -3px 0 0 #e04f9a !important; 
    box-shadow: -3px 0 0 0 #e04f9a !important; 
    box-shadow: 3px 0 0 0 #e04f9a !important; 
    box-shadow: 0 0 0 3px #e04f9a !important; */

    /* background-color: #b33f7b;
    border-color: #e04f9a;
    color: white!important;
    box-shadow: 0 3px 0 0 #b33f7b !important; 
    box-shadow: 0 -3px 0 0 #b33f7b !important; 
    box-shadow: -3px 0 0 0 #b33f7b !important; 
    box-shadow: 3px 0 0 0 #b33f7b !important; 
    box-shadow: 0 0 0 3px #b33f7b !important; */
}

.colorNoneOutlineBlue {
    box-shadow: none!important;
    background-color: rgba(255,255,255,0);
    color: white;
    box-shadow: 0 3px 0 0 white !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 white !important; /* Border top */
    box-shadow: -3px 0 0 0 white !important; /* Border left */
    box-shadow: 3px 0 0 0 white !important; /* Border right */
    box-shadow: 0 0 0 3px white !important; /* All the borders by using the spread properties */
}
.colorNoneOutlineBlue:hover,
.StandardOutlineBlue:focus,
.StandardOutlineBlue:active{
    background-color: #0E3A5C;
    border-color: #0E3A5C;
    color: white!important;
    box-shadow: 0 3px 0 0 #0E3A5C !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #0E3A5C !important; /* Border top */
    box-shadow: -3px 0 0 0 #0E3A5C !important; /* Border left */
    box-shadow: 3px 0 0 0 #0E3A5C !important; /* Border right */
    box-shadow: 0 0 0 3px #0E3A5C !important; /* All the borders by using the spread properties */

    /* background-color: #e04f9a;
    border-color: #e04f9a;
    color: white!important;
    box-shadow: 0 3px 0 0 #e04f9a !important; 
    box-shadow: 0 -3px 0 0 #e04f9a !important; 
    box-shadow: -3px 0 0 0 #e04f9a !important; 
    box-shadow: 3px 0 0 0 #e04f9a !important; 
    box-shadow: 0 0 0 3px #e04f9a !important; */

    /* background-color: #b33f7b;
    border-color: #e04f9a;
    color: white!important;
    box-shadow: 0 3px 0 0 #b33f7b !important; 
    box-shadow: 0 -3px 0 0 #b33f7b !important; 
    box-shadow: -3px 0 0 0 #b33f7b !important; 
    box-shadow: 3px 0 0 0 #b33f7b !important; 
    box-shadow: 0 0 0 3px #b33f7b !important; */
}
.StandardOutlineBlue {
    background-color: #0E3A5C;
    /* border-color: #0E3A5C; */
    color: white!important;
}
.StandardOutlineBlue:hover {
    background-color: #0E3A5C;
    /* border-color: #0E3A5C; */
    color: white!important;
}

.colorHollowOrange {
    box-shadow: none!important;
    background-color: rgba(255,255,255,0);
    color: #EB6915;
    box-shadow: 0 3px 0 0 #EB6915 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #EB6915 !important; /* Border top */
    box-shadow: -3px 0 0 0 #EB6915 !important; /* Border left */
    box-shadow: 3px 0 0 0 #EB6915 !important; /* Border right */
    box-shadow: 0 0 0 3px #EB6915 !important; /* All the borders by using the spread properties */
}
.colorHollowOrange:hover,
.orangeOutline:focus,
.orangeOutline:active{
    background-color: #EB6915;
    border-color: #EB6915;
    color: white!important;
    box-shadow: 0 3px 0 0 #EB6915 !important; /* Border bottom */
    box-shadow: 0 -3px 0 0 #EB6915 !important; /* Border top */
    box-shadow: -3px 0 0 0 #EB6915 !important; /* Border left */
    box-shadow: 3px 0 0 0 #EB6915 !important; /* Border right */
    box-shadow: 0 0 0 3px #EB6915 !important; /* All the borders by using the spread properties */

    /* background-color: #e04f9a;
    border-color: #e04f9a;
    color: white!important;
    box-shadow: 0 3px 0 0 #e04f9a !important; 
    box-shadow: 0 -3px 0 0 #e04f9a !important; 
    box-shadow: -3px 0 0 0 #e04f9a !important; 
    box-shadow: 3px 0 0 0 #e04f9a !important; 
    box-shadow: 0 0 0 3px #e04f9a !important; */

    /* background-color: #b33f7b;
    border-color: #e04f9a;
    color: white!important;
    box-shadow: 0 3px 0 0 #b33f7b !important; 
    box-shadow: 0 -3px 0 0 #b33f7b !important; 
    box-shadow: -3px 0 0 0 #b33f7b !important; 
    box-shadow: 3px 0 0 0 #b33f7b !important; 
    box-shadow: 0 0 0 3px #b33f7b !important; */
}






/*none*/
.colorWhite {
    box-shadow: none!important;
    background-color: rgba(255,255,255,0);
    color: white;
    border: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px;
    
}
.colorWhite:hover,.colorWhite:focus,.colorWhite:active{
    color: #be8c1a;
}





/*green inverted*/
.colorStandardInverted {
    background-color: white;
    color: #16c194!important;
}
.colorStandardInverted:hover,.colorStandardInverted:active {
    background-color: white;
    color: #169f7b!important;
}

/*green transparent*/
/* .colorStandardTransparent {
    background-color: rgb(190, 178, 178, 0);
    color: white!important;
    border: white solid 4px !important;
    padding-top: 3px !important;
}
.colorStandardTransparent:hover,.colorStandardTransparent:active {
    background-color: #16c194;
    border-color: #16c194;
    color: white!important;
    border: none !important;
    padding-top: 7px !important
} */

/*yellow inverted*/
.colorYellowInverted {
    background-color: white;
    color: #f4b321!important;
}
.colorYellowInverted:hover,.colorYellowInverted:active {
    color: #be8c1a!important;
}

/*pink inverted*/
.colorPinkInverted {
    background-color: white;
    color: #e04f9a!important;
}
.colorPinkInverted:hover,.colorPinkInverted:active {
    background-color: white;
    color: #b33f7b!important;
}

/*Teal inverted*/
.colorTealInverted {
    background-color: white;
    color: #0c989d!important;
}
.colorTealInverted:hover,.colorTealInverted:active {
    background-color: white;
    color: #0c989d!important;
}





.underline{
    background-image: linear-gradient(to right,#f8d17a 0,#f8d17a 100%);
    background-position: 0 1.9em;
    background-repeat: repeat-x;
    background-size: 2px 2px;
}

.btn {
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    outline: none;
    margin-left: 3px;
    margin-right: 3px;
    line-height: 24px;   
    
}
.btn:hover {
-ms-transform: translate(0px, -0.8px); /* IE 9 */
-webkit-transform: translate(0px, -0.8px); /* Safari */
transform: translate(0px, -0.8px);
}
/* .btn:focus {
    box-shadow: none;
} */
.aStyles{
    padding-top: 6px;
}

.circleChevron{
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    background-color: #16C194;
}
.circleChevron:hover, .circleChevron:active {
    background-color: #e04f9a;
    -ms-transform: translate(0px, -0.8px); /* IE 9 */
    -webkit-transform: translate(0px, -0.8px); /* Safari */
    transform: translate(0px, -0.8px);
}
.circleChevronDisabled {
    background-color: #294764;
}
.circleChevronBlue{
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    background-color: #193958;
    color: white;
}
.circleChevronBlue:hover, .circleChevronBlue:active {
    background-color: #e04f9a;
    -ms-transform: translate(0px, -0.8px); /* IE 9 */
    -webkit-transform: translate(0px, -0.8px); /* Safari */
    transform: translate(0px, -0.8px);
}
.circleChevronBlueDisabled {
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    background-color: #193958;
    color: white;
    cursor:default;
}
.circleChevronLeafGreen{
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    background-color: #60AE4C;
    color: white;
}
.circleChevronLeafGreen:hover, .circleChevronLeafGreen:active {
    background-color: #4a8f39;
    -ms-transform: translate(0px, -0.8px); /* IE 9 */
    -webkit-transform: translate(0px, -0.8px); /* Safari */
    transform: translate(0px, -0.8px);
}
.circleChevronLeafGreenDisabled {
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out;
    background-color: #60AE4C;
    color: white;
    cursor:default;
}

.sizeSmall{
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 12px;
    font-size: 70%;
    line-height: 23px;
}

.sizeNormal{
    padding-left: 16px;
    padding-right: 16px;
    height: 38px;
    border-radius: 19px;
}
.sizeLarge{
    padding-left: 28px;
    padding-right: 28px;
    height: 50px;
    border-radius: 30px;
    line-height: 36px;
}
.sizeXL{
    padding-left: 40px;
    padding-right: 40px;
    height: 80px;
    border-radius: 40px;
    line-height: 62px;
    
}
.wrap{
    white-space: normal;
}
.cursor{
    cursor: pointer;
}

.disabled{
    opacity: .3 !important;
    cursor: default;
}

.btn:active,.btn:focus, .btn:hover {
    outline: none;
    text-decoration: none;
}


.fixedWidth{
    min-width: 120px;
}
.block{
    display: block;
}
.inlineBlock{
    display: inline-block;
}
.linkCenter{
    display: block;
    text-align: center;
    width: 100%;
}
.bold{
    font-weight: bold
}
.noPadding{
    padding:0!important;
}
.compact {
    margin-bottom: -.6rem;    
}
.anchorMiddle{
    display:table-cell;	
    vertical-align:middle;    
}

/* Fix chevron icon to be centred */
.chevronLeft {
    padding: .4rem 1rem .5rem .75rem;
}
.chevronRight {
    padding: .5rem .75rem .5rem 1rem;    
}

.lowercase {
    text-transform: none;
}

@media only screen and (min-width: 768px) {
    .alignLeft {
        text-align: left;
    }
}

