/* These fixed widths keep in step with the bootstrap cols used */

.imageGrow {
    flex-basis: 0;
    flex-grow: 1;
}

.colWidth75 {
    width: 100%;
}

@media (min-width: 576px) {}


@media (min-width: 768px) {
    .colWidth75 {
        width: calc((100% - 720px)/2 + 420px);
    }
}

@media (min-width: 992px) {
    .colWidth75 {
        /* width: calc((100% - 720px)/2 + 500px); */
        width: calc((100% - 720px)/2 + 440px);
    }
}


@media (min-width: 1200px) {
    .colWidth75 {
        /* width: calc((100% - 720px)/2 + 576px); */
        width: calc((100% - 720px)/2 + 456px);
    }
}

@media (min-width: 1400px) {
    .colWidth75 {
        /* width: calc((100% - 720px)/2 + 700px); */
        width: calc((100% - 720px)/2 + 456px);
    }
}