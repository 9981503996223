
.card{
    -webkit-transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out,transform .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out ,transform .15s ease-in-out;
    border: none;
    /* box-shadow: 0 1px 4px 0 rgba(0,0,0,.15); */
    /* box-shadow:0 8px 12px -5px rgba(0,0,0,.4); */
    box-shadow: 0px 3px 6px #00000029;

}
.cardTrans{
    background-color:rgba(255,255,255,0.7);
}
.cardHover:hover{
    opacity: 0.8;
    -ms-transform: translate(0px, -2px); /* IE 9 */
    -webkit-transform: translate(0px, -2px); /* Safari */
    transform: translate(0px, -2px);

}

/*green*/
.greenHover:hover {
    box-shadow: 0 0 0 0.2rem rgba(22,159,123,0.5);
}

/*yellow*/
.yellowHover:hover {
    box-shadow: 0 0 0 0.2rem rgba(244,179,33,0.5);
}

/*pink*/
.pinkHover:hover {
    box-shadow: 0 0 0 0.2rem rgba(224,79,154,0.5);
}

@keyframes throb {
    0%   { background-color: #f7f7f7; }
    70%   { background-color: #ededed; }
    100%   { background-color: #f7f7f7; }
  }
  .animate{
    animation-name: throb;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }