.textFocus:focus{
    border-color: #f4b321;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244,179,33,0.5);
}
.textFocusReadOnly:focus{
    outline: 0;
    border-color: rgb(206, 212, 218);
    box-shadow: none;
}
.textFocusReadOnly{
    cursor: default;
}