.res-tab-tab{
    text-transform: uppercase;
    border-bottom-style: solid;
    border-bottom-color: #c6c6c6;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-top-color: #ffffff;
    border-top-width: 1px;
    border-left-style: none;
    border-right-style: none;
    padding: 10px;
    display: block;
    font-size:14px;
    letter-spacing: 0.6px;
    font-weight: bold;
    transition: background-color .15s, color .15s;   
}

.res-tab-tab:first-child{
    border-top-style: none;
}
.res-tab-tab:last-child{
    border-bottom-style: none;
}

a.res-tab-tab:hover{
    text-decoration: none;
    color:#be8c1a;
    background-color: rgb(250,250,250);
}

.res-tab-tab.active{
    background-color: #ecdfc2;
    border-bottom-color: #f4b321;
    border-bottom-style: solid;
    border-bottom-width: 4px;
}
/* prevent override */
.res-tab-tab.active:hover{
    background-color: #ecdfc2; 
    color:black;
}

.res-tab-disabled{
    opacity: 0.5;
    cursor: default;
}
a.res-tab-tab{
    color:black;
}
