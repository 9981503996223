.card{
    -webkit-transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out,transform .15s ease-in-out, background-color .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, opacity .15s ease-in-out ,transform .15s ease-in-out, background-color .15s ease-in-out;
    box-shadow: 3px 3px #D3D3D3;
    border: 1px solid #C0C0C0;
    border-radius: 3px;
    padding: 12px;
    cursor: pointer;
    background: none;
    width:100%;
    display:block;
    text-align: left;
}

.cardHover:hover{
    -ms-transform: translate(0px, -0.8px); /* IE 9 */
    -webkit-transform: translate(0px, -0.8px); /* Safari */
    transform: translate(0px, -0.8px);
    box-shadow: 0 0 0 0.2rem rgba(244,179,33,0.5);
}
.card,.cardHover:active,.cardHover:focus,.cardHover:hover{
    outline: none;
}
.checked{
    background-color: rgba(244,179,33,0.2);
    border-color: rgba(244,179,33,1);
    outline: none;
}
.disabled{
    background-color: rgba(242,242,242);
    border-color: #C0C0C0;
    outline: none;
    cursor: default !important;
    color:rgb(120,120,120);
}