@media only screen and (min-width: 768px) and (max-width: 830px) {
  .editLabel{
      font-size: 12px;
      overflow-wrap: break-word;
  }
}

@media only screen and (max-width: 435px) {
  .editLabel{
      font-size: 12px;
      overflow-wrap: break-word;
  }
}

/* @media only screen and (max-width: 700px) {
  .grid {
    display: grid !important; 
    gap: 1rem !important;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr)) !important;
    -ms-display: flex !important;
    -ms-flex-wrap: wrap !important;
  }  
} */

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center
  }
}

